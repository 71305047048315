<template>
  <div class="ele-body ele-body-card dsp">
    <!-- 顶部统计卡片 -->
    <el-row :gutter="15" style="margin-bottom: 20px">
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">注册用户</div>
            <el-tooltip content="指标说明" placement="top">
              <i class="el-icon-_question ele-text-placeholder" style="cursor: pointer;"></i>
            </el-tooltip>
          </div>
          <div class="analysis-chart-card-num">
            <span v-if="pageData.member">{{pageData.member.total || 0}}</span>
            <span v-else>0</span>
          </div>
          <div class="analysis-chart-card-content" style="padding-top: 18px; padding-bottom: 4px">
            <span class="ele-action">
              <span v-if="pageData.member">周同比{{pageData.member.week_ratio}}</span>
              <span v-else>0%</span>
              <i :class="pageData.member&&pageData.member.week_ratio&&pageData.member.week_ratio.split('%')[0]>0?'el-icon-caret-top ele-text-success':'el-icon-caret-bottom ele-text-danger'"></i>
            </span>
            <span class="ele-action">
              <span v-if="pageData.member">日同比{{pageData.member.day_ratio}}</span>
              <span v-else>日同比0%</span>
              <i :class="pageData.member&&pageData.member.day_ratio&&pageData.member.day_ratio.split('%')[0]>0?'el-icon-caret-top ele-text-success':'el-icon-caret-bottom ele-text-danger'"></i>
            </span>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text" v-if="pageData.member">昨日新增 {{pageData.member.yesterday_total}}</div>
          <div class="analysis-chart-card-text" v-else>昨日新增 0</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">启动次数</div>
            <el-tag size="mini" type="success">本周</el-tag>
          </div>
          <div class="analysis-chart-card-num">
            <span v-if="pageData.start_num">{{pageData.start_num.total}}</span>
            <span v-else>0</span>
          </div>
          <div class="analysis-chart-card-content" style="padding-top: 18px; padding-bottom: 4px">
            <span class="ele-action">
              <span v-if="pageData.start_num">周同比{{pageData.start_num.week_ratio}}</span>
              <span v-else>周同比0%</span>
              <i :class="pageData.start_num&&pageData.start_num.week_ratio&&pageData.start_num.week_ratio.split('%')[0]>0?'el-icon-caret-top ele-text-success':'el-icon-caret-bottom ele-text-danger'"></i>
            </span>
            <span class="ele-action">
              <span v-if="pageData.start_num">日同比{{pageData.start_num.day_ratio}}</span>
              <span v-else>日同比0%</span>
              <i :class="pageData.start_num&&pageData.start_num.day_ratio&&pageData.start_num.day_ratio.split('%')[0]>0?'el-icon-caret-top ele-text-success':'el-icon-caret-bottom ele-text-danger'"></i>
            </span>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text" v-if="pageData.start_num">昨日登录 {{pageData.start_num.yesterday_count}}</div>
          <div class="analysis-chart-card-text" v-else>昨日登录 0</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">用户发布</div>
            <el-tag size="mini" type="success">本月</el-tag>
          </div>
          <div class="analysis-chart-card-num">{{countFB||0}}</div>
          <div class="analysis-chart-card-content">
            <ele-chart ref="visitChart" style="height: 40px;" :option="visitChartOption"/>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text" v-if="pageData.user_publish">总发布量 {{pageData.user_publish.total||0}}</div>
          <div class="analysis-chart-card-text" v-else>总发布量 0</div>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="12">
        <el-card class="analysis-chart-card" shadow="never">
          <div slot="header" class="ele-cell">
            <div class="ele-cell-content">搜索量</div>
<!--            <el-tag size="mini" type="primary" style="margin-right: 10px">昨日</el-tag>-->
            <el-tag size="mini" type="success">本月</el-tag>
          </div>
          <div class="analysis-chart-card-num">{{countSearch||0}}</div>
          <div class="analysis-chart-card-content">
            <ele-chart ref="payNumChart" style="height: 40px;" :option="payNumChartOption"/>
          </div>
          <el-divider/>
          <div class="analysis-chart-card-text" v-if="pageData.video_serch">总搜索量 {{pageData.video_serch.total||0}}</div>
          <div class="analysis-chart-card-text" v-else>总搜索量 0</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 销售额、访问量 -->
    <el-row :gutter="15" style="background: #fff; margin-bottom: 20px">
      <el-col :lg="18" :md="16">
        <el-card
            shadow="never"
            header=""
            body-style="padding: 10px 5px 0 0;">
          <div class="ele-cell demo-monitor-tool" style="padding-right: 10px">
            <div class="ele-cell-content" style="position: relative; ">
              <el-tabs
                  style="padding-left: 10px"
                  v-model="saleSearch.type"
                  class="demo-monitor-tabs"
                  @tab-click="onSaleTypeChange">
                <el-tab-pane label="新用户注册" name="saleroom"/>
              </el-tabs>
            </div>
            <div class="ele-inline-block hidden-xs-only">
              <el-radio-group v-model="saleSearch.dateType" size="small" style="margin-right: 10px" @change="reqDateChange">
                <el-radio-button label="yesterday">昨日</el-radio-button>
                <el-radio-button label="sevendays">近七天</el-radio-button>
                <el-radio-button label="thismonth">本月</el-radio-button>
                <el-radio-button label="thisyear">本年</el-radio-button>
              </el-radio-group>
            </div>
            <div class="ele-inline-block hidden-sm-and-down">
              <el-date-picker
                  unlink-panels
                  type="daterange"
                  class="ele-fluid"
                  end-placeholder="结束日期"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  v-model="saleSearch.searchTime"
                  @change="searchReqDatePickerChange"
                  range-separator="至" size="small"/>
            </div>
          </div>
          <ele-chart
              ref="saleChart"
              style="height: 285px;"
              :option="saleChartOption"/>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="8" style="padding: 0 10px 20px; margin-top: 20px">
        <div class="demo-monitor-title" style="margin-bottom: 20px">
          注册地区top7
        </div>
        <div
            v-for="(item, index) in region_list"
            :key="index"
            class="demo-monitor-rank-item ele-cell" style="margin-bottom: 16px; padding-right: 10px">
          <el-tag
              size="mini"
              type="info"
              :effect="index < 3 ? 'dark' : 'light'"
              :color="index < 3 ? '#314659' : 'hsla(0, 0%, 60%, .2)'"
              style="border-color: transparent; margin-right: 4px"
              class="ele-tag-round">
            {{ index + 1 }}
          </el-tag>
          <div class="ele-cell-content">{{ item.name }}</div>
          <div class="ele-text-secondary">{{ item.value }}</div>
        </div>
      </el-col>
    </el-row>
    <!-- 最近1小时访问情况 -->
    <el-row :gutter="15" style="background: #fff">
      <el-col :lg="18" :md="16">
        <el-card
          shadow="never"
          header=""
          body-style="padding: 10px 5px 0 0;">
          <div class="ele-cell-content" style="position: relative; top:4px; display: flex">
            <el-tabs
                style="flex: 1; padding-left: 10px"
                v-model="saleSearch.type"
                class="demo-monitor-tabs"
                @tab-click="onSaleTypeChange">
              <el-tab-pane label="视频浏览量" name="saleroom"/>
            </el-tabs>
            <div class="ele-inline-block hidden-xs-only">
              <el-radio-group v-model="videoViewSearch" size="small" style="margin-right: 10px" @change="videoViewDateChange">
<!--                <el-radio-button label="yesterday">昨日</el-radio-button>-->
                <el-radio-button label="sevendays">近七天</el-radio-button>
                <el-radio-button label="thismonth">本月</el-radio-button>
                <el-radio-button label="thisyear">本年</el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <ele-chart
              style="height: 285px;"
            ref="visitHourChart"
            :option="visitHourChartOption"/>
        </el-card>
      </el-col>
      <el-col :lg="6" :md="8" style="padding: 20px 20px 20px">
        <div class="demo-monitor-title" style="margin-bottom: 10px">
          近7天热播视频标签top10
        </div>
        <div
            v-for="(item, index) in hotVideoTabs"
            :key="index"
            class="demo-monitor-rank-item ele-cell" style="margin-bottom: 16px">
          <el-tag
              size="mini"
              type="info"
              :effect="index < 3 ? 'dark' : 'light'"
              :color="index < 3 ? '#314659' : 'hsla(0, 0%, 60%, .2)'"
              style="border-color: transparent; margin-right: 4px"
              class="ele-tag-round">
            {{ index + 1 }}
          </el-tag>
          <div class="ele-cell-content">{{ item.name }}</div>
          <div class="ele-text-secondary">{{ item.value }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EleChart from 'ele-admin/packages/ele-chart';
import axios from "axios";
import config from "../config"

export default {
  name: 'DashboardAnalysis',
  components: {EleChart},
  data() {
    return {
      // 支付笔数
      payNumData: [],
      countFB:0,
      countSearch:0,
      // 销售量搜索参数
      saleSearch: {
        dateType: 'yesterday',
        searchTime:[],
      },
      videoViewSearch:'sevendays',
      fbData:[],
      searchNumData:[],
      reqNumData:[],
      hotVideoTabs:[],
      // 销售量数据
      saleroomData: [],
      // 销售量排名数据
      saleroomRankData: [],
      // 最近1小时访问情况数据
      visitHourData: [],
      // 词云数据
      hotSearchData: [],
      pageData :{},
      region_list:[],
    };
  },
  computed: {
    // 发布量
    visitChartOption() {
      return {
        color: '#975fe5',
        tooltip: {
          trigger: 'axis',

        },
        grid: {
          top: 10,
          bottom: 0,
          left: 0,
          right: 0
        },
        xAxis: [
          {
            show: false,
            type: 'category',
            boundaryGap: false,
            data: this.fbData.map(d => d.date)
          }
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.5
            },
            data: this.fbData.map(d => d.value)
          }
        ]
      };
    },
    // 搜索量
    payNumChartOption() {
      return {
        tooltip: {
          trigger: 'axis',

        },
        grid: {
          top: 10,
          bottom: 0,
          left: 0,
          right: 0
        },
        xAxis: [
          {
            show: false,
            type: 'category',
            data: this.searchNumData.map(d => d.date)
          }
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'bar',
            data: this.searchNumData.map(d => d.value)
          }
        ]
      }
    },
    // 注册趋势
    saleChartOption() {
      return {
        title: {
          subtext: '注册趋势',
          left: 20,
          top: -14,
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: [
          {
            type: 'category',
            data: this.reqNumData.map(d => d.date)
          }
        ],
        yAxis: [
          {
            type: 'value',
            minInterval:1
          },
        ],
        series: [
          {
            type: 'bar',
            data: this.reqNumData.map(d => d.value)
          }
        ]
      };
    },
    // 最近1小时访问情况折线图配置
    visitHourChartOption() {
      /*if (!this.visitHourData.length) {
        return {};
      }*/
      return {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['浏览量'],
          right: 20
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.visitHourData.map(d => d.date)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '浏览量',
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {
              opacity: 0.5
            },
            data: this.visitHourData.map(d => d.value)
          }
        ]
      }
    }
  },
  mounted() {
    this.getPageData()
    this.getReqData()
   this.getVideoViewData()
  },
  methods: {
    getPageData(){
      axios.get(`${config.dataServerUrl}/Dashboard/analysis`).then(res=>{
        if(res.data.code === 0){
          const resData = res.data.data
          this.pageData = resData
          if(resData.user_publish){ // 用户发布
              this.getPayNumData(resData.user_publish)
          }
          if(resData.video_serch){
              this.getSaleroomData(resData.video_serch)
          }
          if(resData.region_list){
            this.region_list = resData.region_list
          }
          if(resData.video_tag){
            this.hotVideoTabs = resData.video_tag
          }
        }
      })
    },
    /* 获取支付笔数数据 */
    getPayNumData(user_publish) {
      this.fbData = user_publish.list
      this.countFB = this.fbData.reduce((accumulator, current) => accumulator + current.value, 0)
    },
    /* 获取销售量数据 */
    getSaleroomData(video_serch) {
      this.searchNumData = video_serch.list
      this.countSearch = this.searchNumData.reduce((accumulator, current) => accumulator + current.value, 0)
    },
    getReqData(){
      const queryParams = {}
      if(this.saleSearch.searchTime && this.saleSearch.searchTime.length === 2){
        this.saleSearch.dateType = ''
        queryParams.starttime = this.saleSearch.searchTime[0]
        queryParams.endtime = this.saleSearch.searchTime[1]
      }else{
        queryParams.date_type = this.saleSearch.dateType || 'yesterday'
      }
      axios.post(`${config.dataServerUrl}/Dashboard/getUserRegistrPlate`,queryParams).then(res=>{
        if(res && res.data.data){
          this.setQerChatData(res.data.data)
        }
      })
    },
    getVideoViewData(){
      axios.post(`${config.dataServerUrl}/Dashboard/getVideoViewsPlate`,{date_type: this.videoViewSearch}).then(res=>{
        if(res && res.data.data){
          this.getVisitHourData(res.data.data)
        }
      })
    },
    reqDateChange(){
      this.saleSearch.searchTime = []
      this.getReqData()
    },
    searchReqDatePickerChange(){
      if(!this.saleSearch.searchTime.length){
        this.saleSearch.dateType = ''
      }
      this.getReqData()
    },
    setQerChatData(data){
      this.reqNumData = data
    },
    videoViewDateChange(){
      this.getVideoViewData()
    },
    /* 获取最近1小时访问情况数据 */
    getVisitHourData(data) {
      this.visitHourData = data
    },
    /* 销售量tab选择改变事件 */
    onSaleTypeChange() {
      this.getSaleroomData();
    }
  },
  activated() {
    ['visitChart', 'payNumChart', 'saleChart', 'visitHourChart', 'hotSearchChart'].forEach((name) => {
      this.$refs[name].resize();
    });
  }
}
</script>

<style lang="scss" scoped>
.ele-body{
  background: #F5F6F7;
  overflow-x: hidden;
}
.title {
  font-size: 1.6rem;
  color: #333;
  padding-bottom: 10px;
  font-weight: bold;
  padding-left: 1.4rem;
}
.analysis-chart-card-content {
  height: 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  width: 100%;
}
.chat-content {
  box-sizing: border-box;
}

.chat-item {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: .7rem;
  position: relative;
}

.chat-title {
  font-size: 1.29rem;
  color: #333333;
  margin-bottom: 1.4rem;
}

.chat-box {
  background: #f0eeee;
  min-height: 26rem;
}

.totle-data {
  position: absolute;
  top: 160px;
  left: 0;
  z-index: 999;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;

  div {
    flex: 1;

    span {
      margin-right: 20px;
    }
  }

  span {
    color: #333;
  }
}

.search-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;

  .form-label {
    padding-right: 1rem;
  }
}
.ele-cell{
  display: flex;
  flex-direction: row;
  align-items: center;

  .ele-cell-content{
    flex: 1;
  }
}
.demo-monitor-tool{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.demo-monitor-title{

}
.ele-text-danger {
  color: #F56C6C;
}

.ele-text-success {
  color: #67C23A;

}
</style>

<style>
.dsp .el-tabs__nav-wrap::after{
  background-color: #fff;
}
.dsp .el-divider--horizontal{
  margin: 0 0 10px 0;
}
.dsp .el-card{
  border: none;
  border-right: 1px solid #eae6e6;
}
.ele-body-card .ele-charts {
  overflow: inherit !important;
}
</style>
